import { easyPoiExcel } from '../exportsExcel';
import http from '../http';

const path = '/fds/business/monitor/data/';
export const getList = (data) => {
  return http.get(path + 'model-list', data);
};
export const exportExeclWarning = (data) => {
  const { name, query } = data;
  return easyPoiExcel(path + 'export-excel-v2', name, query, 600000);
};
export const seachExeclWarning = (data) => {
  const { query } = data;
  return http.post(path + 'export-excel-page', query, { 'Content-Type': 'application/json;charset=UTF-8' });
};
export const seachTable = (data) => {
  const { query } = data;
  return http.post(path + 'statistics-page', query, { 'Content-Type': 'application/json;charset=UTF-8' });
};
