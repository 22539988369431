<template>
  <div>
    <div class='form-box rounded-lg white elevation-2'>
      <v-form ref='from'>
        <v-row align='center'>
          <v-col md='3'>
            <k-tree-select
              v-model='query.organizationUUID'
              :items='items'
              input-width='300px'
              label='所属机构'
              placeholder='请选择所属机构'
            />
          </v-col>
          <v-col md='3'>
            <k-date-range-select
              v-model='dateRange'
              label='日期'
              @change='dateRangeChange'
            ></k-date-range-select>
          </v-col>
          <v-col offset-md='3'>
            <k-warning-btn
              :loading='loading'
              class='btns'
              icon='mdi-magnify'
              text='查询'
              @click='search'
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
    <k-crud-table :value="page" v-if='columns.length' :headers='columns' :items='tableData' :total='total'
                  @pageChanged='pageChanged'></k-crud-table>
  </div>
</template>

<script>
import { seachTable } from '@/api/reportManage/warningExport';

export default {
  name: 'index',
  data() {
    return {
      loading: false,
      query: {

        organizationUUID:[]
      },
      items: [],
      length: 10,
      columns: [],
      tableData: [],
      total: 0,
      page: 1,
      dateRange: [],
    };
  },
  created() {
    this.getTree();
    this.search();
  },
  mounted() {
    const width = this.$refs.table.clientWidth;
    this.colW = width / (this.length + 2);
  },
  methods: {
    pageChanged(page) {
      this.page = page;
    },
    getTree() {
      this.$api.organization.organization
        .getOrTree()
        .then(({ code, data }) => {
          if (code === 200) {
            this.items = data;
          }
        });
    },
    dateRangeChange(e) {
      console.log(e);
      if (e?.length) {
        this.query.timeStartStr = `${e[0]}`;
        this.query.timeEndStr = `${e[1]}`;
      }else{
        this.query.timeStartStr = undefined;
        this.query.timeEndStr = undefined;
      }
    },
    async search() {
      const query = {
        ...this.query,
        organizationUUID: this.query.organizationUUID[0]|| '',
        page: this.page,
      };
      const res = await seachTable({
        query,
      });
      let records = res.data[0] ? res.data[0].records || [] : [];
      if (records[0]) {
        let columns = records[0].map((v, i) => {
          const obj = {
            text: `${!i ? '所属机构' : v.name}${!i ? '' : '(未确定条数/总数)'}`,
            value: i ? 'value' + i : 'name',
            sortable: false,
            align: 'center',
          };
          return obj;
        });

        this.columns = columns;
      }
      this.total = res.data[0] ? res.data[0].total : 0;
      this.tableData = records.map((v) => {
        let obj = {};
        v.forEach((j, i) => {
          if (i) {
            obj['value' + i] = `${j.uncertain}条/${j.sum}条`;
          }else{
            obj.name = j.name
          }

        });
        console.log(obj);
        return obj;
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.form-box, ::v-deep .v-form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
.form-box{
  padding: 0 20px;
}
.form-box {
  margin-bottom: 20px;
}

::v-deep .v-data-table-header {
  tr {
    th {
      white-space: nowrap !important;
    }
  }
}

.btns {
  vertical-align: middle;
  margin-left: 20px;
  float: right;
}

</style>
